import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import GalleryHeader from "@components/klips/gallery-header"
import Layout from "@components/layout"
import Html from "@components/html"
import Heading from "@components/elements/heading"
import Flex from "@components/elements/flex"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import Div from "@components/elements/div"
import { useTheme } from "styled-components"
import { HTMLWrapper } from "@components/common/html-wrapper"
import { GalleryGrid } from "@components/gallery/grid"

const KlipPage = ({ pageContext, data }) => {
  const klip = data.klip
  const { color } = useTheme()

  return (
    <Layout
      seo={{
        title:
          "250+ pre-built data connectors for your dashboard. Get connected!",
        description:
          "Want to easily connect your data to a dashboard? Klipfolio has over 250 prebuilt data connectors! Learn how you can connect your favorite services with Klipfolio.",
      }}
      fullWidth={true}
    >
      <GalleryHeader
        name={klip.name}
        subtitle={
          klip.description_gallery ?? (
            <Html parseElements html={klip.description} />
          )
        }
        newsletterCta
        shadow={false}
        ctaCategory={klip.name}
        kpi
        links={[
          {
            text: "Gallery",
            url: "/gallery/klip",
          },
          { text: klip.name },
        ]}
        link={klip.link}
        services={klip.services}
        image={klip.image}
      />
      <Div textContainer margin="2rem auto 6rem">
        <Flex gap="2rem">
          {klip.description_gallery && (
            <HTMLWrapper margin="0 0 1rem" html={klip.description} />
          )}
          {klip.services.map(service => (
            <Flex gap="1rem" key={service.id}>
              <Heading margin="0 0 0.5rem" color={color.blue600} as="h2">
                About {service.name}
              </Heading>
              <Html parseElements html={service.description} />
            </Flex>
          ))}
        </Flex>
      </Div>
      {pageContext.relatedKlips?.length > 0 && (
        <Div container margin="0 auto 6rem">
          <Heading color={color.blue600} margin="0 0 2rem" as="h2">
            Related Klips
          </Heading>
          <GalleryGrid klips={pageContext.relatedKlips} />
        </Div>
      )}
      <KlipsEOPCta />
    </Layout>
  )
}

KlipPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default KlipPage

export const pageQuery = graphql`
  query KlipPage($id: String!) {
    klip: galleryKlips(id: { eq: $id }) {
      image {
        ...imageData
      }
      description
      description_gallery
      services {
        id
        name
        logo {
          ...imageData
        }
        description
        slug
        just_docs
      }
      name
      link
      slug
    }
  }
`
